var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"X4iRnQFUWQMIXJyEEjrF7"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { replayIntegration } from '@sentry/react';

const sentryEnv = window?.env?.['SENTRY_ENVIRONMENT'] || 'development';
const sentryDisabled = window?.env?.['SENTRY_ENABLED'] === 'false' ?? sentryEnv === 'development';

Sentry.init({
    dsn: 'https://c0037c6724829090cfa426daa9e2f96e@o4507888866492416.ingest.de.sentry.io/4507888877043792',
    environment: sentryEnv,
    integrations: [
        replayIntegration({
            maskAllInputs: false,
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: sentryEnv === 'production' ? 1.0 : 0,
    enabled: !sentryDisabled,
    ignoreErrors: ['TypeError: Failed to fetch', 'ApolloError: Failed to fetch', 'SyntaxError: Unexpected token ?'],
});
